import { Form, Input, Modal } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';

interface ParentProps {
  activeProductAddon: any;
  onCancel: any;
  onAddonProductChangeHandler: Function;
}

export const EditProductAddon = ({ activeProductAddon, onCancel, onAddonProductChangeHandler }: ParentProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...activeProductAddon.item });
  }, [activeProductAddon, form]);

  const onFormSubmitHandler = ({
    data_box_name,
    data_name,
    data_price,
    data_org,
    data_save,
    data_discount,
    data_discount_price,
    data_size,
    quantity,
    tag
  }: any) => {
    const addon = {
      ...activeProductAddon.item,
      data_box_name,
      data_name,
      data_price,
      data_org,
      data_save,
      data_discount,
      data_discount_price,
      data_size,
      quantity,
      tag
    };
    onAddonProductChangeHandler(addon);
    form.resetFields(['data_org', 'data_save', 'data_discount', 'data_discount_price', 'tag']);
  };

  return (
    <Modal onOk={() => form.submit()} closable={false} open={activeProductAddon.active} onCancel={onCancel}>
      <Form form={form} onFinish={onFormSubmitHandler}>
        <FormItem name="data_box_name" label="Box Name">
          <Input placeholder="Product Name in select dropdown" />
        </FormItem>
        <FormItem name="data_name" label="Name">
          <Input placeholder="Product Name" />
        </FormItem>
        <FormItem name="data_price" label="Bundle Price">
          <Input placeholder="price" />
        </FormItem>
        <FormItem name="data_discount_price" label="Bundle Price for page UI">
          <Input placeholder="Discounted Price" />
        </FormItem>
        <FormItem name="quantity" label="Quantity">
          <Input placeholder="Quantity" />
        </FormItem>
        <FormItem name="data_org" label="Original Price">
          <Input placeholder="original price" />
        </FormItem>
        <FormItem name="data_save" label="Save Price">
          <Input placeholder="save price" />
        </FormItem>
        <FormItem name="data_discount" label="Discount(%)">
          <Input placeholder="discount(%)" />
        </FormItem>
        <FormItem name="data_size" label="Size">
          <Input placeholder="Size(S, L, XL, etc)" />
        </FormItem>
        <FormItem name="tag" label="Tag">
          <Input placeholder="Tag from a parent" />
        </FormItem>
      </Form>
    </Modal>
  );
};
