import Frame1 from '../../../../assets/images/addon/frame1.png';
import Frame2 from '../../../../assets/images/addon/frame2.png';
import Frame4 from '../../../../assets/images/addon/frame4.png';
import Frame5 from '../../../../assets/images/addon/frame5.jpg';
import Frame6 from '../../../../assets/images/addon/frame6.png';
import Frame7 from '../../../../assets/images/addon/frame7.png';
import Frame8 from '../../../../assets/images/addon/frame8.png';
import JourneyFrame1 from '../../../../assets/images/addon/journeyFrame1.jpg';
import WFrame1 from '../../../../assets/images/warranty/warFrame1.png';
import WFrame2 from '../../../../assets/images/warranty/warFrame2.png';
import WFrame3 from '../../../../assets/images/warranty/warFrame3.png';
import WFrame4 from '../../../../assets/images/warranty/warFrame4.png';
import WFrame5 from '../../../../assets/images/warranty/warFrame5.jpg';
import { IFrame } from '../../../../types';

export const onMouseEnterHandler = (className: any, document: any) => {
  const documentContainer = document.querySelectorAll(className);
  for (let i = 0; i < documentContainer.length; i++) {
    documentContainer[i].scrollIntoView();
    documentContainer[i].style.outline = '5px solid greenyellow';
  }
};
export const onMouseLeaveHandler = (className: any, document: any) => {
  const documentContainer = document.querySelectorAll(className);
  for (let i = 0; i < documentContainer.length; i++) {
    documentContainer[i].style.outline = 'unset';
  }
};

export const jsonPrettify = (json: any): string => {
  if (typeof json === 'object' && json !== null) {
    const pretty = JSON.stringify(json, undefined, 4);
    return pretty;
  }

  try {
    const obj = JSON.parse(json);
    return jsonPrettify(obj);
  } catch (e) {
    return json;
  }
};

export const htmlPartals: IFrame[] = [
  {
    img: Frame1,
    frame: 'addonFrame1',
    html: "<p><strong>ONE TIME OFFER:</strong> By placing your order today, we'll add a remote for each heater unit for only an additional <span class='font-weight-bold text-success remote-each-price cb-clickbump-price-2'>$11</span><span class='font-weight-bold text-success'>/ea.</span> <span>and ship it with your order for FREE.</span> </p>",
    section: 'checkout',
    type: 'normal',
  },
  {
    img: Frame2,
    frame: 'addonFrame2',
    html: "<p class='order-bump-paragraph'><span id='orderBumpHref' align='center' class='order-bump-href'> ONE TIME OFFER </span> <span class='priceDefault'>(<span class='remote-each-price'>$13.59</span><span>/ea<span>)</span>:</span> <span id='orderBumpSpan-3'> ADD DESCRIPTION HERE </span> </p>",
    section: 'checkout',
    type: 'normal',
  },
  {
    img: Frame7,
    frame: 'addonFrame7',
    html: '<p><strong>One-Chance Offer:(<span class="remote-each-price">$9.99</span>/ea)</strong> Skip the Cloud Storage ($20/Month) and Save $240 instantly per year per camera when you grab an SD Card! The SD Card mini allows you to record activity caught by your CamTrack without using Cloud Storage, then review from your iPhone or Android device. The SD Card fits directly into the CamTrack and is easy to install. Save today and skip the cloud storing by grabbing an SD Card today! 88% Of Buyers Choose This Option.</p>',
    section: 'checkout',
    type: 'normal',
  },
  {
    img: Frame8,
    frame: 'addonFrame8',
    html: '',
    section: 'checkout',
    type: 'multi',
  },
  {
    img: Frame5,
    frame: 'addonFrame5',
    html: "<img src='https://offer.getrivalsmartwatch.com/offer/1/app/desktop/images/checkout-now-v1/protector-img.png' align='left' style='margin-right: 12px;margin-top:5px;' width='100'><p><strong>One-Chance Offer:</strong> By placing your order today, we'll add a screen protector for each of your smart watches for only an additional <span class='font-weight-bold text-success remote-each-price cb-clickbump-price-2'>$11</span><span class='font-weight-bold text-success'>/ea</span> <span>and ship it with your order.</span> </p>",
    section: 'checkout',
    type: 'normal',
  },
  {
    img: Frame6,
    frame: 'addonFrame6',
    html: '',
    styles: {
      colors: ['blue', 'pink', 'orange', 'red', 'white'],
    },
    section: 'checkout',
    type: 'normal',
  },
  {
    img: JourneyFrame1,
    frame: 'journeyFrame1',
    html: "<p class='np-txt'>100% Guaranteed Package Protection From Damage, Loss & Theft For Just $3.75/ea. In the event your delivery is damaged, stolen or lost during transit. We will replace it 100% free. No questions asked. Protect your package and your family today!</p>",
    section: 'checkout',
    type: 'journey',
  },
  {
    img: Frame4,
    frame: 'addonFrame4',
    html: '',
    section: 'checkout',
    type: 'normal',
  },
  {
    img: WFrame1,
    frame: 'warrantyFrame1',
    html: '',
    section: 'warranty',
    type: 'normal',
  },
  {
    img: WFrame2,
    frame: 'warrantyFrame2',
    html: '',
    section: 'warranty',
    type: 'normal',
  },
  {
    img: WFrame3,
    frame: 'warrantyFrame3',
    html: '',
    section: 'warranty',
    type: 'normal',
  },
  {
    img: WFrame4,
    frame: 'warrantyFrame4',
    html: '',
    section: 'warranty',
    type: 'normal',
  },
  {
    img: WFrame5,
    frame: 'warrantyFrame5',
    html: '',
    section: 'warranty',
    type: 'normal',
  },
];
