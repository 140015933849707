import { Form, Input, Select } from 'antd';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { templatesAtom } from '@/atoms/templates';
import { IFormProps, ITemplate } from '@/types';

import { jsonPrettify } from '../Pages/TemplateEditor/CheckoutListMenu/HelperFunctions';

const { Option } = Select;

export const PREVIEW_URL_PREFIX = 'https://preview.hydra-cms.com';

export const TemplateEditForm = ({ formData, onFormReady }: IFormProps<ITemplate>) => {
  const templates = useAtomValue(templatesAtom);
  const [form] = Form.useForm();

  useEffect(() => {
    onFormReady(form);
  }, [onFormReady, form]);

  useEffect(() => {
    if (!!formData) {
      form.setFieldsValue({
        ...formData,
        meta: jsonPrettify(formData.meta),
        template_host_url: formData.template_host_url.replace(PREVIEW_URL_PREFIX, ''),
      });
    }
  }, [form, formData]);

  return (
    <Form form={form} layout="vertical" initialValues={formData}>
      <Form.Item
        name="name"
        label="Insert new Template Name"
        rules={[{ required: true, message: 'Please input Template Name' }]}
      >
        <Input placeholder="Input Template Name name" />
      </Form.Item>
      <Form.Item
        name="page_type"
        label="Page Type"
        rules={[{ required: true, message: 'Please input Insert Page Type' }]}
      >
        <Select disabled={!!formData} placeholder="Select Page Type">
          <Option value="interstitial">Interstitial</Option>
          <Option value="checkout">Checkout</Option>
          <Option value="upsell1">Upsell1</Option>
          <Option value="upsell2">Upsell2</Option>
          <Option value="thankyou">Thank you</Option>
          <Option value="warranty">Warranty</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="layout"
        label="Template Layout"
        // eslint-disable-next-line no-useless-escape
        rules={[{ required: true, message: 'Place page layout in input field', pattern: RegExp(/^[a-zA-Z0-9_\-]+$/) }]}
      >
      
        <Select
          showSearch
          placeholder="Select Template Layout"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
          options={templates.layout.map((v) => {
            return { value: v, label: v };
          })}
        />
      </Form.Item>
      <Form.Item
        name="template_host_url"
        label="Page Preview URL"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please input valid page path, it must start and end with slash',
            pattern: RegExp(/^\/([A-z0-9-_+]+\/)*$/),
          },
        ]}
      >
        <Input placeholder="Input Page URL" addonBefore={PREVIEW_URL_PREFIX} />
      </Form.Item>
      <Form.Item
        name="meta"
        label="Page meta values"
        rules={[{ required: true, message: 'Place page metas in input field' }]}
      >
        <Input.TextArea rows={12} placeholder="Paste page metas here" />
      </Form.Item>
    </Form>
  );
};
